import Link from 'components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const ComponentWrapper = tw.div`
bg-accent_grey_2 px-[30px] py-[48px] lg:p-6
`;
const Heading = tw.p`
text-accent_blue_1 text-[18px] font-[500] leading-[27px] mb-2
`;
const Description = tw.p`
text-[#7B7F8A] text-[16px] leading-[24px] mb-4
`;
const StyledLink = tw(Link)`
bg-white hover:bg-white hover:opacity-70 text-hubble_blue border border-hubble_blue text-[16px] font-[500] hover:text-hubble_blue hover:bg-accent_grey_2 py-2 px-3
`;
const FAQHelpBox = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiFaqPage {
        nodes {
          UserPromptCTAButtonLabel
          UserPromptCTAButtonLink
          UserPromptCTAHeader
          UserPromptCTASubHeader
        }
      }
    }
  `);
  const {
    UserPromptCTAButtonLabel,
    UserPromptCTAButtonLink,
    UserPromptCTAHeader,
    UserPromptCTASubHeader,
  } = data.allStrapiFaqPage.nodes[0];
  return (
    <ComponentWrapper>
      <Heading>{UserPromptCTAHeader}</Heading>
      <Description>{UserPromptCTASubHeader}</Description>

      <StyledLink to={UserPromptCTAButtonLink}>
        {UserPromptCTAButtonLabel}
      </StyledLink>
    </ComponentWrapper>
  );
};

export default FAQHelpBox;
