import { Select } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import FAQHelpBox from './FAQHelpBox';
import FAQList from './FAQList';
import { FAQContentType } from './types';

interface MobileFAQContentProps {
  handleChangeTopic: (newValue: string) => void;
  currentFAQ: FAQContentType;
  FAQContent: FAQContentType[];
}

const MobileHeading = tw.p`
text-[16px] font-[500] leading-[24px] text-base_text mb-0
`;
const HeadingAndSearchWrapper = tw.div`
px-[30px] lg:px-0
`;
const MobileFAQContent = ({
  handleChangeTopic,
  currentFAQ,
  FAQContent,
}: MobileFAQContentProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiFaqPage {
        nodes {
          TabHeader
        }
      }
    }
  `);
  const { TabHeader } = data.allStrapiFaqPage.nodes[0];
  return (
    <div className="lg:hidden">
      <HeadingAndSearchWrapper>
        <MobileHeading>{TabHeader}</MobileHeading>
        <Select
          defaultValue={currentFAQ.value}
          onChange={handleChangeTopic}
          style={{ width: '100%' }}
          options={FAQContent}
          size="large"
        />
      </HeadingAndSearchWrapper>
      <FAQList {...currentFAQ} />
      <FAQHelpBox />
    </div>
  );
};

export default MobileFAQContent;
