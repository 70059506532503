import Link from 'components/Link';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import React from 'react';

const NotFoundScreenWrapper = tw.div`
px-[30px] text-[#585C67] text-center max-w-[390px] mx-auto pb-10
`;
const NotFoundHeading = tw.p`
text-[24px] font-[500] leading-[36px] mb-2
`;
const NotFoundDescription = tw.p`
whitespace-pre-line text-[16px] leading-[24px] mb-4 lg:mb-6
`;
const StyledLink = tw(Link)`
bg-white hover:bg-white hover:opacity-70 text-hubble_blue border border-hubble_blue text-[16px] font-[500] hover:text-hubble_blue hover:bg-accent_grey_2 py-2 px-3
`;

const NotFoundScreen = () => {
  const { formatMessage } = useIntl();
  return (
    <NotFoundScreenWrapper>
      <NotFoundHeading>
        {formatMessage({ id: 'FAQ.notFound.heading' })}
      </NotFoundHeading>
      <NotFoundDescription>
        {formatMessage({ id: 'FAQ.notFound.description' })}
      </NotFoundDescription>
      <StyledLink to="/contact-us">
        {formatMessage({ id: 'FAQ.notFound.button' })}
      </StyledLink>
    </NotFoundScreenWrapper>
  );
};
export default NotFoundScreen;
