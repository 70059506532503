import { Input } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

const { Search } = Input;
interface FAQSearchProps {
  setSearchKey: React.Dispatch<React.SetStateAction<string>>;
  searchKey: string;
}
const FAQSearch = ({ setSearchKey, searchKey }: FAQSearchProps) => {
  const onSearch = (keywords: string) => {
    setSearchKey(keywords);
  };

  const { formatMessage } = useIntl();
  return (
    <div className="mt-[54px] lg:mt-2 w-full max-w-[610px] mx-auto mb-[28px] lg:mb-[64px]">
      <Search
        placeholder={formatMessage({ id: 'FAQ.search.placeholder' })}
        allowClear
        onSearch={value => onSearch(value)}
        size="large"
        value={searchKey}
        onChange={e => setSearchKey(e.target.value)}
      />
    </div>
  );
};

export default FAQSearch;
