import {
  StructuredDataTypes,
  STRUCTURED_DATA_CONTEXT,
} from 'components/constant';
import { FAQPageStructureDataType } from 'components/structuredData/types';

export const FAQPageStructureData: FAQPageStructureDataType = {
  '@context': STRUCTURED_DATA_CONTEXT,
  '@type': StructuredDataTypes.FAQPAGE,
  mainEntity: [],
};
