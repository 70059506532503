import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const ComponentWrapper = tw.div`
pt-[75px] text-center text-base_text w-fit mx-auto
`;
const Heading = tw.p`
text-[36px] font-[600] leading-[120%] mb-0 lg:text-[48px] lg:font-[500] lg:leading-[127%] lg:mb-2
`;
const SubHeading = tw.h1`
text-[14px] leading-[150%] mb-0 lg:text-[18px]
`;

const FAQHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiFaqPage {
        nodes {
          PageHeader
          PageSubHeader
        }
      }
    }
  `);
  const { PageHeader, PageSubHeader } = data.allStrapiFaqPage.nodes[0];
  return (
    <ComponentWrapper>
      <Heading>{PageHeader}</Heading>
      <SubHeading>{PageSubHeader}</SubHeading>
    </ComponentWrapper>
  );
};

export default FAQHero;
