import * as React from 'react';
import NewLayout from 'components/NewLayout';
import { graphql } from 'gatsby';
import FAQPage from 'components/pages/FAQ';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import translation from 'intl';

const pageUrl = `${PREFIX_CANONICAL_URL}/frequently-asked-questions`;

const IndexPage = () => {
  return (
    <NewLayout headerStyle="light">
      <BreadcrumbList
        pageLabelId="structuredData.generalFAQ"
        pageUrl={pageUrl}
      />
      <FAQPage />
    </NewLayout>
  );
};
export default withIntl(translation)(IndexPage);

export const Head = ({
  data,
}: {
  data: {
    allStrapiFaqPage: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL data={data.allStrapiFaqPage.nodes[0]} canonicalURL={pageUrl} />
  );
};

export const query = graphql`
  query {
    allStrapiFaqPage {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
