import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw, { styled } from 'twin.macro';
import FAQHelpBox from './FAQHelpBox';
import FAQList from './FAQList';
import { FAQContentType } from './types';

interface WebFAQContentProps {
  handleChangeTopic: (newValue: string) => void;
  currentFAQ: FAQContentType;
  FAQContent: FAQContentType[];
}

const ComponentWrapper = tw.div`
hidden lg:grid lg:grid-cols-[228px_1fr] gap-10 lg:gap-[68px] 
`;
const LeftColumn = styled.div`
  ${tw`sticky top-0`}
  align-self: start;
`;
const Heading = tw.p`
pl-6 text-[20px] leading-[30px] mb-6
`;

const WebFAQContent = ({
  handleChangeTopic,
  currentFAQ,
  FAQContent,
}: WebFAQContentProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiFaqPage {
        nodes {
          TabHeader
        }
      }
    }
  `);
  const { TabHeader } = data.allStrapiFaqPage.nodes[0];
  return (
    <ComponentWrapper>
      <LeftColumn>
        <Heading>{TabHeader}</Heading>

        {FAQContent.map(topic => {
          const buttonCss =
            currentFAQ.value === topic.value
              ? 'bg-hubble_blue text-white'
              : 'text-accent_blue_2';
          return (
            <button
              key={topic.value}
              onClick={() => handleChangeTopic(topic.value)}
              className={`py-[6px] pl-6 text-[16px] font-[500] leading-[24px] mb-4 w-full text-start ${buttonCss}`}
            >
              {topic.label}
            </button>
          );
        })}
        <FAQHelpBox />
      </LeftColumn>

      <FAQList {...currentFAQ} />
    </ComponentWrapper>
  );
};

export default WebFAQContent;
