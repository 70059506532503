import React, { useEffect, useRef } from 'react';
import { FAQContentType } from './types';
import { Collapse } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import tw, { styled } from 'twin.macro';

interface FAQListProps extends FAQContentType {}

const { Panel } = Collapse;

const ComponentWrapper = styled.div`
  ${tw`mt-[52px] lg:mt-0 lg:h-[700px] lg:overflow-auto`}
  [data-theme='compact']
    .site-collapse-custom-collapse
    
    .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    border: 0px;

    .ant-collapse-header {
      padding: 0;
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }
  @media (max-width: 1024px) {
    [data-theme='compact']
      .site-collapse-custom-collapse
      .site-collapse-custom-panel,
    .site-collapse-custom-collapse .site-collapse-custom-panel {
      margin-bottom: 32px;
      padding-bottom: 24px;
      border-bottom: 1px #d8dade solid;
      margin: 0 30px 32px 30px;
    }
  }
`;
const Label = tw.p`
text-[24px] font-[500] leading-[36px] lg:font-[600] lg:leading-[36px] px-[30px] lg:px-0 mb-6
`;
const Question = tw.p`
text-[16px] font-[500] leading-[24px] lg:text-[18px] leading-[30px] text-base_text mb-0 lg:mb-1 text-start
`;
const Answer = tw.p`
whitespace-pre-line pl-[13px] text-[14px] lg:text-[16px] text-subtext mb-0
`;

const FAQList = ({ QAList }: FAQListProps) => {
  const firstElementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const firstElement = firstElementRef.current;
    firstElement?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'start',
    });
  }, [firstElementRef.current]);

  return (
    <ComponentWrapper>
      {QAList?.map(({ id, label, list }, index) => {
        return (
          <div
            className="mb-[40px] lg:mb-[80px]"
            key={id}
            ref={index === 0 ? firstElementRef : null}
          >
            <Label>{label}</Label>

            <Collapse
              bordered={false}
              style={{ background: 'transparent' }}
              expandIcon={({ isActive }) => (
                <UpOutlined
                  rotate={isActive ? 180 : 0}
                  style={{ fontSize: 15, paddingTop: '8px' }}
                />
              )}
              className="site-collapse-custom-collapse"
            >
              {list.map(({ q: question, a: answer }) => (
                <Panel
                  className="site-collapse-custom-panel"
                  header={<Question>{question}</Question>}
                  key={question}
                >
                  <Answer>{answer}</Answer>
                </Panel>
              ))}
            </Collapse>
          </div>
        );
      })}
    </ComponentWrapper>
  );
};

export default FAQList;
