import LayoutContainer from 'components/LayoutContainer';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import React, { useEffect, useMemo, useState } from 'react';
import FAQHero from './FAQHero';
import FAQSearch from './FAQSearch';
import translation from './translation';
import MobileFAQContent from './MobileFAQContent';
import WebFAQContent from './WebFAQContent';
import tw from 'twin.macro';
import { isEmpty } from 'lodash';
import { FAQContentType, FAQItemType } from './types';
import NotFoundScreen from './NotFoundScreen';
import { graphql, useStaticQuery } from 'gatsby';
import { FAQPageStructureData } from './constants';
import { StructuredDataTypes } from 'components/constant';
import StructuredDataScript from 'components/structuredData/StructuredDataScript';

const ComponentWrapper = tw.div`
mt-[84px] bg-accent_grey_1 lg:pb-[90px]
`;

const FAQPage = () => {
  const [FAQContent, setFAQContent] = useState<FAQContentType[]>([]);
  const [currentFAQ, setCurrentFAQ] = useState<FAQContentType>({
    value: '',
    label: '',
    QAList: [],
  });
  const [searchKey, setSearchKey] = useState('');

  const data = useStaticQuery(graphql`
    query {
      allStrapiGeneralFaq(sort: { fields: strapi_id, order: ASC }) {
        nodes {
          SystemValue
          SystemLabel
          ModuleList {
            id
            ModuleName
            QuestionAndAnswer {
              header
              description
            }
          }
        }
      }
    }
  `);
  const systemList: FAQContentType[] = useMemo(
    () =>
      data.allStrapiGeneralFaq.nodes.map(
        ({ SystemValue, SystemLabel, ModuleList }: Record<string, any>) => ({
          value: SystemValue,
          label: SystemLabel,
          QAList: ModuleList.map(
            ({ id, ModuleName, QuestionAndAnswer }: Record<string, any>) => ({
              id,
              label: ModuleName,
              list: QuestionAndAnswer.map(
                ({ header, description }: Record<string, any>) => {
                  const mainEntityStructureData = {
                    '@type': StructuredDataTypes.QUESTION as const,
                    name: header as string,
                    acceptedAnswer: {
                      '@type': StructuredDataTypes.ANSWER as const,
                      text: description as string,
                    },
                  };
                  FAQPageStructureData.mainEntity.push(mainEntityStructureData);
                  return {
                    q: header,
                    a: description,
                  };
                },
              ),
            }),
          ),
        }),
      ),
    [data],
  );
  useEffect(() => {
    setFAQContent(systemList);
    setCurrentFAQ(systemList[0]);
  }, [data]);

  const handleChangeTopic = (newValue: string) => {
    const foundFAQ =
      FAQContent.find(({ value }) => value === newValue) ?? FAQContent[0];
    setCurrentFAQ(foundFAQ);
  };
  useEffect(() => {
    if (!searchKey) {
      setFAQContent(systemList);
      setCurrentFAQ(systemList[0]);
    } else {
      let matchesContent: FAQContentType[] = [];
      systemList.forEach(({ QAList, ...content }) => {
        let newQAList: FAQItemType[] = [];

        QAList.forEach(({ list, ...item }) => {
          const questionMatches = list.filter(({ q }) =>
            q.toLowerCase().includes(searchKey.toLowerCase()),
          );
          if (isEmpty(questionMatches)) return;
          newQAList.push({ ...item, list: questionMatches });
        });

        if (isEmpty(newQAList)) return;
        matchesContent.push({ ...content, QAList: newQAList });
      });
      setFAQContent(matchesContent);
      setCurrentFAQ({ ...matchesContent[0] });
    }
  }, [searchKey]);

  return (
    <ComponentWrapper>
      <StructuredDataScript data={FAQPageStructureData} />
      <LayoutContainer className="px-[30px] lg:px-0">
        <FAQHero />
        <FAQSearch setSearchKey={setSearchKey} searchKey={searchKey} />
      </LayoutContainer>
      {isEmpty(FAQContent) ? (
        <NotFoundScreen />
      ) : (
        <>
          <LayoutContainer className="px-[30px] lg:px-0">
            <WebFAQContent
              currentFAQ={currentFAQ}
              handleChangeTopic={handleChangeTopic}
              FAQContent={FAQContent}
            />
          </LayoutContainer>
          <MobileFAQContent
            currentFAQ={currentFAQ}
            handleChangeTopic={handleChangeTopic}
            FAQContent={FAQContent}
          />
        </>
      )}
    </ComponentWrapper>
  );
};

export default withIntl(translation)(FAQPage);
